<template>
  <v-container>
    <v-card
      outlined
      class="pa-5 mx-auto col-lg-12 col"
    >
      <div class=" d-flex justify-space-between">
        <div class="mb-5">
          <h2 class="headline text-capitalize mb-2">Notification</h2>

       
        </div>
    

      <div class="action-btns">
        <v-btn @click="notifyDialog = true" color="primary">
          <v-icon class="pr-3">mdi-check</v-icon> Notify</v-btn
        >
      </div>
        </div>
         <v-data-table
            :headers="headers"
            :items="notificationList"
            class="elevation-1"
            :loading="loading"
          >
          <template v-slot:item.createdDate="{ item }">
            {{formatDate(item)}}
          </template>
          </v-data-table>
    </v-card>
    

    <v-dialog v-model="notifyDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Send Construction Notification </v-card-title>
        <v-text-field label="Message" v-model="message"></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="notifyDialog = false">
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="approve"> Send </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      notifyDialog: false,
      message: "",
      loading: false,
      notificationList: [],
      headers: [
        { text: "Title", value: "title" },
        { text: "Body", value: "body" },
        { text: "Sent Date", value: "createdDate" },
        // { text: "Customer", value: "customer.name" },
        // { text: "Phone", value: "customer.phone" },
      ],
    };
  },
  methods: {
    approve() {
      let data = {
        constructionId: this.$route.params.id,
        message: this.message,
      };
      this.$General
        .notifyConstruction(data)
        .then((data) => {
          this.$toastr.success("Notification Sent");
          this.notifyDialog = false;
          this.getNotificationList();
          this.message = "";
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
    getNotificationList() {
      this.$General
        .getConstructionNotificationList(this.$route.params.id)
        .then((data) => {
          this.notificationList = data.data.data.list;
          console.log("Notification", this.notificationList);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  mounted() {
    this.getNotificationList();
  },
};
</script>
