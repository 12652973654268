<template>
  <v-container>
    <v-row>
      <v-col md="8">
         <v-card
      outlined
      class="pa-5 d-flex justify-space-between mx-auto"
    >
      <div class="">
        <div class="mb-5">
          <h2 class="headline text-capitalize mb-2">
            {{ constructionDetails.title }}
          </h2>
          <div class="body">Status: {{ constructionDetails.status }}</div>
        <div class="body">Created Date: {{ constructionDetails.createdDate ? formatDate(constructionDetails.createdDate, 'Do MMM YYYY hh:mm A') : '-'}}</div>
         
        </div>
         <div class="body" v-if="constructionDetails.subCategory && constructionDetails.subCategory.price">
      Price: Rs {{ constructionDetails.subCategory.price }} per {{ constructionDetails.subCategory.unit }}
    </div>
    <div class="body">Area: {{ constructionDetails.area }} </div>
    <div class="body" v-if="constructionDetails.subCategory && constructionDetails.subCategory.price">
      Cost: NPR. {{ parseInt(constructionDetails.area) * parseInt(constructionDetails.subCategory.price) }}
    </div>
        <div class="body">
          Inquiry Type: {{ constructionDetails.inquiryType }}
        </div>

        <div v-if="constructionDetails.subCategory">
           <div class="body">
          Category: {{ constructionDetails.subCategory.category.name }}
        </div>
         <div class="body">
          SubCategory: {{ constructionDetails.subCategory.name }}
        </div>
        </div>
       

        
      </div>

      <div class="action-btns">
        <v-btn @click="approveOnSiteDialog = true" color="primary" v-if="constructionDetails.status!=='Construction Approved'">
          <v-icon class="pr-3">mdi-check</v-icon> Approve</v-btn
        >
        <v-btn @click="disapproveOnSiteDialog = true" color="red" v-if="constructionDetails.status!=='Construction Disapproved'">
          <v-icon class="pr-3">mdi-alpha-x</v-icon>
          Disapprove</v-btn
        >
      </div>
    </v-card>
      </v-col>
      <v-col md="4">
        <v-card  outlined
      class="pa-5 mx-auto">
      <h2 class="headline text-capitalize mb-2">
           Customer Details
          </h2>
            <div>
               Name: {{constructionDetails.customer.name}}
            </div>
            <div>
                Email: {{constructionDetails.customer.email}}
            </div>
            <div>
                Phone: {{constructionDetails.customer.phone}}
            </div>
        </v-card>
      </v-col>
    </v-row>
   

   

    <notification />

      <div v-if="constructionDetails.design">
         <DesignItem :designDetails="constructionDetails.design.measurements" title="Measurements" v-if="constructionDetails.design.measurements"/>
    <DesignItem :designDetails="constructionDetails.design.sampleDesigns" title="Sample Designs" v-if="constructionDetails.design.sampleDesigns"/>
    <DesignItem :designDetails="constructionDetails.design.renderViews" title="Render Views" v-if="constructionDetails.design.renderViews"/>
    <DesignItem :designDetails="constructionDetails.design.detailedDesigns" title="Detailed Designs" v-if="constructionDetails.design.detailedDesigns"/>
      </div>
   

    <v-dialog v-model="approveOnSiteDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h4"> Approve Detailed Design </v-card-title>
        <h4 class="pt-4 text-center">Are you sure you want to perform this action?</h4>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="approveOnSiteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="approve"> Approve </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="disapproveOnSiteDialog" max-width="600">
      <v-card class="pa-5">
        <v-card-title class="text-h5"> Disapprove Construction</v-card-title>

        <v-text-field
          label="Remarks"
          v-model="disapproveRemarks"
        ></v-text-field>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="disapproveOnSiteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="disapprove">
            Disapprove
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Notification from "./Notification.vue";
import DesignItem from "./DesignItem.vue";
export default {
  components: {
    Notification,
    DesignItem
  },
  data() {
    return {
      approveOnSiteDialog: false,
      disapproveOnSiteDialog: false,
      datePickerMenu: false,

      constructionDetails: {},
      approveOnSiteConsultationDetails: {
        approvedDate: "",
        remarks: "",
        id: this.$route.params.id,
      },
      disapproveRemarks: "",
    };
  },
  methods: {
    getConstructionById() {
      this.$General
        .getConstructionById(this.$route.params.id)
        .then((data) => {
          this.constructionDetails = data.data.data;
          console.log(data.data.data)
        })
        .catch((error) => {
          console.log(error);
        });
    },
    approve() {
      this.$General
        .approveConstruction(this.$route.params.id)
        .then((data) => {
          this.$toastr.success("Onsite Consultation Approved Successfully");
          this.approveOnSiteDialog = false;
          this.getConstructionById();
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
    disapprove() {
      let data = {
        id: this.$route.params.id,
        remarks: this.disapproveRemarks,
      };
      this.$General
        .disapproveConstruction(data)
        .then((data) => {
          this.$toastr.success("This item has been marked complete");
          this.getConstructionById();
          this.disapproveOnSiteDialog = false;
          this.disapproveRemarks = '';
        })
        .catch((error) => {
          if (error.response) {
            this.$toastr.error(error.response.data.message);
          } else {
            this.$toastr.error("Something Went Wrong");
          }
        });
    },
  },

  mounted() {
    this.getConstructionById();
  },
};
</script>


approvedDate: (...)
area: (...)
customer: (...)
description: (...)
id: (...)
inquiryType: (...)
link: (...)
preferredDate: (...)
remarks: (...)
status: (...)
title: (...)