<template>
    <v-container>
          <v-card outlined style="padding: 40px 20px">
      <div class="d-flex justify-space-between">
        <h1 class="headline">{{title}}</h1>
      </div>
       <div class="row">
      <div
        class="col-md-3"
        v-for="item in designDetails"
        :key="item.id"
      >
        <v-card outlined>
          <v-img
            :src="file_url+ item.fileName"
            height="200"
          ></v-img>
          <v-card-subtitle>
            {{ formatDate(item.uploadedDate) }}
          </v-card-subtitle>

          <v-card-title class="text-capitalize">{{
            item.caption
          }}</v-card-title>
        </v-card>
      </div>
    </div>
          </v-card>
    </v-container>
</template>


<script>
export default {
    data(){
        return{
            file_url:process.env.VUE_APP_FILE_URL+'/',
        }
    },
    props:{
        designDetails:{
            required: true
        },
        title:{
            required: true
        }
    },
    mounted(){
        console.log(this.designDetails);
    }
}
</script>


<style scoped>

</style>